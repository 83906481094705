<template>
    <div>
        <div style="margin-left: -10px;">
            <!--table-filter
                :table-filter="tableFilter"
                :operator="filterOperator"
                @click-search="onClickSearch"
                @click-reset="onClickResetSearch"
                :mutiple="true"
            /-->
            <member-list-filter :table-filter="tableFilter"  @click-search="onClickSearch" />
        </div>
        <v-server-table
            class="member-list-table"
            ref="tblRef"
            :name="table.name"
            :columns="table.columns"
            :options="table"
            @loading="onLoading"
            @loaded="onLoaded"
            @error="onError"
        >
            <template slot="beforeTable">
                <!-- <load-spinner v-if="isLoading || isRequestLoading" -->
                <load-spinner v-if="isRequestLoading"
                    :message="loadingMessage"
                ></load-spinner>

                <div class="text-right">
                    <span>{{ selectedMembers.length }} {{ $t('customPoint.selected_members') }}</span>
                    <button type="button" @click="selectAllData"
                        class="btn btn-default btn-bold btn-upper btn-font-md"><i class="fa" :class="{'fa-check':labelSelectAll=='Pilih Semua','fa-undo':labelSelectAll!='Pilih Semua'}"></i>{{labelSelectAll}}</button>
                </div>
                <br/>
            </template>

            <div v-bind:key="key"
                v-for="(item, key) in table.customListFilter"
                :slot="'filter__' + key">
                <select-dropdown-multiple
                    :is-disabled="false"
                    :repo-name="item.repo"
                    @update:changedValue="onFilterSelected"
                    :ref="'filter__' + key"
                    :selected-dropdown="item.values"
                    :render-select="item.renderSelect"
                    :id-select-dropdown="key"
                    />
            </div>

            <template slot="actions" slot-scope="props">
                <button
                    @click="onClickSelect(props.row)"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Select"
                >
                    <i class="fa fa-check"></i>
                </button>
            </template>
        </v-server-table>
    </div>
</template>

<script>
import { isNullOrUndefined } from "util";
import { ServerTable, Event } from "vue-tables-2";
import SelectDropdownMultiple from "./../SelectDropdownMultiple";
import TableFilter from "./TableFilter";
import MemberListFilter from "./MemberListFilter";

import MemberRepository from './../../../repositories/MemberRepository';
import { mapState, mapMutations, mapActions } from "vuex";
import LoadSpinner from "./../CustomLoadSpinner";
Vue.use(ServerTable, {}, false,'bootstrap4');
Vue.use(Event);

const getOperatorTypeParam = type => {
    if (type === "date") return "between";
    if (type === "number")  return "equal";
    if (type === "select")  return "equal";
    if (type === "multiselect")  return "in";
    return "contains";
};

const getValueParam = (type, value) => {
    if (type === "date") {
        return value.start + "," + value.end;
    }
    if (type === "select") {
        return value.start + "," + value.end;
    }
    if (type === "multiselect") {
        let valueIn = '';
        for(let ix in value){
            valueIn += value[ix].id;
            if(ix < (value.length - 1)){
                valueIn = valueIn + ",";
            }
        }
        return valueIn;
    }
    return value;
};

const defaultOptions = {
    //   sendInitialRequest: false,
    filterByColumn: false,
    toMomentFormat: "YYYY-MM-DD",
    dateFormat: "DD MMM YYYY",
    perPage: 50,
    perPageValues: [0],
    texts:{
        filterBy: "Cari ...",
    },
    sortIcon: {
        base: "fa",
        is: "fa-sort",
        up: "fa-sort-up",
        down: "fa-sort-down "
    },
    requestAdapter(data) {
        let columnsFilter = this.columnsFilter;
        let customColumn = this.customColumn;
        let direction = (data.ascending == 1) ? "asc" : "desc";
        let orderBy = data.orderBy ? data.orderBy : "id";

        if (customColumn && customColumn[orderBy] != null) {
            orderBy = customColumn[orderBy];
        }

        var search = Object.keys(data.query).map(key => {
            let fiedlType = columnsFilter.find(f => f.key === key).type;
            let keyName = key;
            if (customColumn && customColumn[key] != null) {
                keyName = customColumn[key];
            }
            return {
                name: keyName,
                type: getOperatorTypeParam(fiedlType),
                value: getValueParam(fiedlType, data.query[key])
            };
        });

        if(data.queryListFilter){
            var searchCustom = Object.keys(data.queryListFilter).map(key => {
                let fiedlType = this.customListFilter[key].type;
                let keyName = key;
                if (customColumn && customColumn[key] != null) {
                    keyName = customColumn[key];
                }
                if(data.queryListFilter[key] && data.queryListFilter[key].length > 0){
                    return {
                        name: keyName,
                        type: getOperatorTypeParam(fiedlType),
                        value: getValueParam(fiedlType, data.queryListFilter[key])
                    };
                }
                return false;
            });
            search = search.concat(searchCustom);
        }

        return {
            search: search,
            page: data.page,
            size: data.limit,
            sort: orderBy + ":" + direction
        };
    },
    responseAdapter(responese) {
        return {
            data: responese.data,
            count: responese.count
        };
    }
};

export default {
    props: {
        isReloadData: { type: Boolean },
        repository:{
            type:Object,
            default(){
                return MemberRepository;
            }
        }
    },
    components: {
        TableFilter,
        MemberListFilter,
        LoadSpinner,
        SelectDropdownMultiple
    },
    data() {
        var vtx = this;
        let tableOptions = {
            ...defaultOptions,
            ...{
                name: "MEMBERS_TABLE",
                columns: [
                    "id",
                    "name_on_card",
                    "member_no",
                    "point",
                    "loyalty",
                    "joined_date",
                    "user_id",
                    "loyalty_tier_name",
                    "actions"
                ],
                sortable: [
                    "id",
                    "name_on_card",
                    "member_no",
                    "point",
                    "loyalty",
                    "joined_date",
                    "user_id",
                    "loyalty_tier_id"
                ],
                filterable: false,
                filterByColumn: true,
                orderBy: { column: 'id', ascending:false },
                customColumn: {
                    loyalty_tier_name: 'loyalty_tier_id',
                },
                cellClasses:{
                    member_no: [
                        {
                            class:'selected',
                            condition: row => {
                                let find =  _.find(this.selectedMembers, item=>{
                                    return item.id == row.id;
                                })
                                return find!==undefined ;

                            }
                        }
                    ]
                },
                headings: {
                    id: "ID",
                    name_on_card: this.$t("member.name_on_card"),
                    member_no: this.$t("member.member_no"),
                    point: this.$t("member.point"),
                    loyalty: this.$t("member.loyalty"),
                    joined_date: this.$t("member.joined_date"),
                    user_id: 'User Id', // this.$t("member.user_id"),
                    loyalty_tier_name: this.$t("member.loyalty_tier")
                },
                customFilters: ['loyalty_tier_name'],
                templates: {
                    joined_date: function(e, row) {
                        return moment(row.joined_date).format("DD MMM YYYY");
                    }
                },
            }
            };

        if (typeof tableOptions.requestFunction !== "function") {
            tableOptions.requestFunction = params => {
                vtx.currentPage = params.page;
                if (vtx.search && vtx.search[0].value != "") {
                    params.search = (params.search) ? params.search : [];
                    params.search = params.search.concat(vtx.search);
                }
                return new Promise((resolve, reject) => {
                    this.fetchData({ params, resolve, reject });
                    this.refreshData(false);
                }).catch(error => {
                    vtx.isLoading = false;
                    var msg = error.message ? error.message : error;
                    toastr.error(msg);
                    throw new Error(msg);
                });
            };
        }

        if (tableOptions.columnsFilter) {
            tableOptions.filterByColumn = true;
            tableOptions.filterable = [];
            tableOptions.dateColumns = [];
            tableOptions.columnsFilter.map((columnsFilter, i) => {
                tableOptions.filterable.push(columnsFilter.key);
                if(columnsFilter.type === "date"){
                    tableOptions.dateColumns.push(columnsFilter.key);
                }
            });
        }

        if(tableOptions.customListFilter){
            vtx.queryListFilter = {};
            tableOptions.customFilters = ['queryListFilter'];
        }

        return {
            isLoading: true,
            // loadingMessage: 'Memuat data ...',
            search: null,
            currentPage: 1,
            table: tableOptions,
            filterOperator:[
                { id: "", alias: "", label: "" },
                {id:'equal',alias:'=',label:'Equals'},
                {id:'lt',alias:'<',label:'Lower Than'},
                {id:'lte',alias:'<=',label:'Lower Than Or Equal To'},
                {id:'gt',alias:'>',label:'Greater Than'},
                {id:'gte',alias:'>=',label:'Greater Than or Equal To'},
                {id:'stw',alias:'^',label:'Starts With'},
                {id:'edw',alias:'$',label:'End With'},
                {id:'contains',alias:'*',label:'Contains'},
                {id:'and',alias:'&',label:'And'},
                {id:'or',alias:'|',label:'Or'},
            ],
            tableFilter:[
                {key:'id',title: "ID",type:'number'},
                {key:'name_on_card',title: this.$t("member.name_on_card"),type:'string'},
                {key:'member_no',title: this.$t("member.member_no"),type:'string'},
                {key:'point',title: this.$t("member.point"),type:'number'},
                {key:'loyalty',title: this.$t("member.loyalty"),type:'number'},
                {key:'joined_date',title: this.$t("member.joined_date"),type:'date'},
                // {key:'user_id',title: this.$t("member.user_id"),type:'number'},
                {key:'user_id',title:'User Id',type:'number'},
                // {key:'loyalty_tier_name',title: this.$t("member.loyalty_tier"),type:'string'},
            ],
            labelSelectAll : 'Pilih Semua',
        };
    },
    computed: {
        ...mapState("MemberListDialog", {
            isRequestLoading: state => state.isLoading,
            loadingMessage: state => state.loadingMessage,
            selectedMembers: state => state.selectedMembers
        })
    },
    methods: {
        ...mapActions({
            fetchData: "MemberListDialog/fetchData"
        }),
        ...mapMutations({
            refreshData: "MemberListDialog/REFRESH_TABLE",
            setSelectedMember:"MemberListDialog/SET_SELECTED_MEMBER",
            setSelectedMembers:"MemberListDialog/SET_SELECTED_MEMBERS",
        }),
        refreshTable: function() {
            this.$refs.tblRef.setPage(this.currentPage);
            this.refreshData(false);
        },
        onLoading: function(data) {
            this.isLoading = true;
        },
        onLoaded: function(data) {
            this.isLoading = false;
        },
        onError: function(data) {
            this.isLoading = false;
        },
        onClickSelect: function(row) {
            this.setSelectedMember(row);
        },
        onClickSearch: function(data) {
            this.search = null;
            if ( data != null && data.length == 0) {
                toastr.warning("Data Pencarian masih kosong");
                return;
            }else{
                this.search = data;
                this.$refs.tblRef.refresh();
            }
        },
        onClickResetSearch: function(data) {
            this.search = null;
            this.$refs.tblRef.refresh();
        },
        onFilterSelected(_value, _key){
            if(_value && _value.length > 0){
                this.queryListFilter[_key] = _value;
            }else{
                delete this.queryListFilter[_key];
            }
            Event.$emit('vue-tables.MEMBERS_TABLE.filter::queryListFilter', this.queryListFilter);
        },
        selectAllData(){
            if(this.labelSelectAll == 'Pilih Semua'){
                this.labelSelectAll = "Batalkan Pilihan";
                this.setSelectedMembers(this.$refs.tblRef.tableData);
            }else{
                this.labelSelectAll = "Pilih Semua";
                this.setSelectedMembers([]);
            }

        }
    },
    mounted() {

    }
};
</script>
