<template>
    <div>
        <base-header :base-title="$t('module.customPoint')"></base-header>
        <div class="claim-point kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="$t('module.customPoint')"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <template
                            v-if="checkPermission('LoyaltyCustomPointResource.POST.store')">
                            &nbsp;&nbsp;
                                <base-button-modal
                                :button-text="$t('customPoint.add')"
                                button-icon="flaticon2-add-1"
                                :button-name="modalName"
                                :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__body">
                    <crud-table
                    ref="crudTable"
                    :table-options="tableOptions"
                    :repository="repository"
                    :permission="permission"
                    :event="fetch"
                    @on-click-edit="onClickEdit"
                    @on-click-delete="onClickDelete"
                    @on-click-view="onClickView"
                    ></crud-table>
                </div>
                <form-modal v-if="isShowModal" @event="fetch"></form-modal>
                <table-member-dialog  v-on:hidemodal="onMemberDialogHideModal" v-if="memberlistIsShowModal"></table-member-dialog>
            </div>
        </div>
    </div>
</template>
<script>

    import BaseHeader from "./../../../components/_base/BaseHeader";
    import BaseTitle from "./../../../components/_base/BaseTitle";
    import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
    import CheckPermission from "./../../../services/checkPermission.service";
    import CrudTable from "./../base/crud-table/crudTable";
    import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
    import { isNullOrUndefined } from "util";
    import FormModal from './FormModal';
    import TableMemberDialog from './TableMemberDialog';
    const customPointRepository = RepositoryFactory.get("customPointRepository");
    import { mapState, mapMutations, mapActions } from "vuex";
    const checkPermission = new CheckPermission();
    Vue.component("ColumnTransactionDate", {
        props: ["data", "index", "column"],
        template: '<p>{{ getFormatedDate }}</p>',
        computed:{
            getFormatedDate(){
                return moment(this.data.transaction_date).format('DD MMMM YYYY HH:MM');
            }
        },
    });

    export default {
        components:{
            BaseHeader,
            BaseTitle,
            BaseButtonModal,
            CrudTable,
            FormModal,
            TableMemberDialog
        },
        methods:{
            ...mapMutations({
                setRepository: 'Crud/SET_REPOSITORY',
                refreshData: 'Crud/REFRESH_TABLE',
                showModal:'Crud/SHOW_MODAL',
                hideModal:'Crud/HIDE_MODAL',
                setModal: 'Crud/SET_MODAL',
                setupTable: 'Crud/SETUP_TABLE',
                setEditData: 'Crud/SET_EDIT_DATA',
                setViewData: 'Crud/SET_VIEW_DATA',
                memberListSetModal: 'MemberListDialog/SET_MODAL',
                memberListShowModal: "MemberListDialog/SHOW_MODAL",

            }),

            checkPermission(method) {
                return checkPermission.isCanAccess(method);
            },
            fetch(){
                this.refreshData(true);
                this.hideModal();
            },
            onMemberDialogHideModal(args){
                $("#" + this.modalName).modal('show');
            },

            onClickEdit(data) {
                data.attachments_new=[];
                data.attachments_delete=[];
                this.$store.commit("Crud/SET_EDIT_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");

            },
            onClickDelete(data) {
                this.$store.dispatch("Crud/deleteById", {
                    deleteRepository: customPointRepository.delete,
                    id: data.id,
                    name: data.title
                });
            },
            onClickView(data) {
                data.attachments_new=[];
                data.attachments_delete=[];

                this.$store.commit("Crud/SET_VIEW_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");
            }

        },
        computed:{

            ...mapState("MemberListDialog", {
                memberlistModalName: state => state.modalName,
                memberlistShowModalMode: state => state.showModalMode,
                memberlistIsShowModal: state => state.isShowModal,
            }),

            ...mapState("Crud", {
                modalName: state => state.modalName,
                isLoaded: state => state.isLoaded,
                table: state => state.table,
                isShowModal: state => state.isShowModal,
                showModalMode: state => state.showModalMode,
                shouldRefreshTable: state => state.shouldRefreshTable,
            })
        },
        watch:{
            shouldRefreshTable: function(newValue, oldValue) {
                if (newValue === true) {
                    this.$refs.crudTable.refreshTable();
                }
            }
        },
        data(){
            var vx = this;
            return {
                repository: customPointRepository,
                permission: {
                    edit: this.checkPermission('LoyaltyCustomPointResource.PUT.update.Integer.key'),
                    delete: this.checkPermission('LoyaltyCustomPointResource.DELETE.destroy.Integer.key'),
                    view: this.checkPermission('LoyaltyCustomPointResource.GET.show.Integer.key')
                },
                tableOptions: {
                    name: "CRUD_TABLE",
                    columns: [
                        'id',  'created_at','type_custom', 'theme_custom', 'title', 'subtitle', 'point','actions'
                    ],
                    sortable: [
                        'id',  'created_at','type_custom', 'theme_custom', 'title', 'subtitle', 'point'
                    ],
                    orderBy: { column: 'id', ascending:false },
                    headings: {
                        id: "ID",
                        created_at:"Tanggal Transaksi",
                        type_custom:"Tipe",
                        theme_custom:"Tema",
                        title:"Judul",
                        subtitle:"Sub Judul",
                        point:"Poin",

                    },
                    customColumn: {

                    },
                    columnsFilter: [
                        { key:'id',type:'number'},
                        { key:'created_at',type:'date'},
                        { key:'type_custom',type:'string'},
                        { key:'theme_custom',type:'string'},
                        { key:'title',type:'string'},
                        { key:'subtitle',type:'string'},
                        { key:'point',type:'number'}
                    ],
                    templates: {

                    },

                }
            }
        },
        created(){
            //this.memberListShowModal();
            this.hideModal();
            this.setRepository(customPointRepository)
            this.setModal("CustomPointModal")
            this.memberListSetModal("MemberListModalDialog")
            window.thisCustomPOint=this;
        }

    }
</script>
