<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{
                            this.customPointModel.id
                                ? (this.modeView ? "" : $t("button.edit"))
                                : $t("button.add")
                        }}
                        {{ $t("module.customPoint") }}
                    </h5>
                </div>
                <form @submit.prevent="true">
                    <div class="modal-body">
                        <input
                            type="hidden"
                            class="form-control"
                            v-model.number="customPointModel.id"
                        />

                        <fieldset>
                            <legend>
                                {{ $t("customPoint.basic_information") }}
                            </legend>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>{{$t("customPoint.type")}}*:</label>
                                        <input
                                            v-model="customPointModel.type_custom"
                                            v-validate="{required:true,max:100 }"
                                            class="form-control"
                                            :class="{'is-invalid': errors.has('type_custom')}"
                                            ref="transactionCode"
                                            :disabled="modeView"
                                            name="type_custom"
                                            :data-vv-as="$t('customPoint.type')"
                                        />
                                        <div v-show="errors.first('type_custom')"
                                            class="custom-invalid-feedback"
                                        >
                                            {{ errors.first("type_custom") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>{{$t("customPoint.theme")}}*:</label>
                                        <input
                                            v-model="customPointModel.theme_custom"
                                            v-validate="{required:true,max:100 }"
                                            class="form-control"
                                            :class="{'is-invalid': errors.has('theme_custom')}"
                                            ref="transactionCode"
                                            :disabled="modeView"
                                            name="theme_custom"
                                            :data-vv-as="$t('customPoint.theme')"
                                        />
                                        <div
                                            v-show="errors.first('theme_custom')"
                                            class="custom-invalid-feedback"
                                        >
                                            {{ errors.first("theme_custom") }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>{{ $t("common.title")}}*:</label>
                                        <input
                                            v-model="customPointModel.title"
                                            v-validate="{required:true,max:100 }"
                                            class="form-control"
                                            :class="{'is-invalid': errors.has('title')}"
                                            ref="transactionCode"
                                            :disabled="modeView"
                                            name="title"
                                            :data-vv-as="$t('common.title')"
                                        />
                                        <div
                                            v-show="errors.first('title')"
                                            class="custom-invalid-feedback"
                                        >
                                            {{ errors.first("title") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>{{ $t("common.subJudul") }}*:</label>
                                        <input
                                            v-model="customPointModel.subtitle"
                                            v-validate="{required:true,max:100 }"
                                            class="form-control"
                                            :class="{'is-invalid': errors.has('subtitle')}"
                                            ref="transactionCode"
                                            :disabled="modeView"
                                            name="subtitle"
                                            :data-vv-as="$t('common.subJudul')"
                                        />
                                        <div
                                            v-show="errors.first('subtitle')"
                                            class="custom-invalid-feedback"
                                        >
                                            {{ errors.first("subtitle") }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>{{ $t("common.point") }}*:</label>
                                        <input
                                            v-model="customPointModel.point"
                                            v-validate="'required'"
                                            class="form-control"
                                            :class="{'is-invalid': errors.has('point')}"
                                            ref="transactionCode"
                                            :disabled="modeView"
                                            name="point"
                                            :data-vv-as="$t('common.point')"
                                            type="number"
                                        />
                                        <div
                                            v-show="errors.first('point')"
                                            class="custom-invalid-feedback"
                                        >
                                            {{ errors.first("point") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div class="row">
                            <div class="col-sm-6">
                                <fieldset>
                                    <legend>
                                        {{ $t("member.member_information") }} *
                                    </legend>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="card-title">
                                                        {{ selectedMembers.length }} {{ $t( "customPoint.selected_members" ) }}
                                                    </div>
                                                    <div class="row">
                                                            <div :class="{'col-md-10':!modeView, 'col-md-12':modeView}" >
                                                                <div class="input-group flex-nowrap">
                                                                    <input type="text" class="form-control" ref="findSelectedMembers" title="Pisahkan dengan koma untuk pencarian multi" placeholder="Cari member" aria-label="Cari Member" aria-describedby="addon-wrapping" v-model="qSearch">
                                                                    <div class="input-group-append">
                                                                        <span @click="qSearch='';$refs.findSelectedMembers.focus();" class="input-group-text" id="addon-wrapping"><i class="fa fa-eraser"></i></span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div
                                                                v-if="!modeView"
                                                                class="col-md-2"
                                                            >
                                                            <button @click="memberListModal" type="button" class="btn btn-primary"><i class="fa fa-plus"></i></button>
                                                                <!--i v-if="1==2"
                                                                    @click="
                                                                        memberListModal
                                                                    "
                                                                    class="fa fa-plus pull-right btn-add-rounded-1 border border-primary"
                                                                ></i-->
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    <div style="max-height:10rem;overflow:scroll">
                                                        <div class="attachment-list">
                                                            <div class="attachment-item"
                                                                v-bind:key="`${member}${index}`"
                                                                v-for="(member,index) in selectedMembers" v-if="filterMember(member)">
                                                                <a target="_blank" class="attachment-link" href="#" >{{ member.member_no }} - {{ member.name_on_card }}</a>
                                                                <button :title="$t('sentences.prepare_to_delete')" @click="setSelectedMember(member)" class="btn attachment-action btn-sm" type="button">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="errors.first('selected_member')"
                                                        class="custom-invalid-feedback"
                                                    >
                                                        {{errors.first("selected_member")}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12"></div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-sm-6">
                                <fieldset>
                                    <legend>
                                        {{ $t("claimPoint.attachments") }}
                                    </legend>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="attachment-list">
                                                <div class="attachment-item" :class="{'danger':isNeedToRemove(file)}" v-bind:key="file.image_path" v-for="(file,index) in customPointModel.attachments">
                                                    <a target="_blank" class="attachment-link" :href="urlImage + file.image_path" >{{ $t('claimPoint.attachments') }} {{ index+ 1 }}</a>
                                                    <button :title="$t('sentences.prepare_to_delete')" v-if="!isNeedToRemove(file) && !modeView" @click="toggleRemove(file)" class="btn attachment-action" type="button">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                    <button :title="$t('sentences.undo_delete_prepare')" v-if="isNeedToRemove(file) && !modeView" @click="toggleRemove(file)" class="btn attachment-action" type="button">
                                                        <i class="fa fa-undo"></i>
                                                    </button>

                                                </div>
                                            </div>
                                            <div v-if="!modeView">
                                                <div
                                                    class="dropzone"
                                                    id="kt-dropzone-one"
                                                    action="gallery/api/photo"
                                                >
                                                    <div
                                                        class="dropzone-msg dz-message needsclick"
                                                    >
                                                        <h1>
                                                            <i
                                                                class="fa fa-upload"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </h1>
                                                        <h3
                                                            class="dropzone-msg-title"
                                                        >
                                                            Upload
                                                        </h3>
                                                        <span
                                                            class="dropzone-msg-desc"
                                                            >{{
                                                                $t(
                                                                    "sentences.drag_image_here_or_click_to_"
                                                                )
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="hideModal()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            v-if="!modeView"
                            type="button"
                            @click="submitData"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import CustomPointModel from "./../../model/custom-point-model";
import { isNullOrUndefined } from "util";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import { mapState, mapMutations, mapActions } from "vuex";
import MemberRepository from "../../repositories/MemberRepository";
import CouponsRepository from "../../repositories/CouponsRepository";

import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import customPointRepository from "../../repositories/CustomPointRepository";
import BlockUI from "./../../../components/_js/BlockUI";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import Photo from "./../../model/photo-model";

const GalleryRepository = RepositoryFactory.get("galleryRepository");
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

VeeValidate.setMode("passive");

export default {
    components: {},

    data() {
        return {
            customPointModel: new CustomPointModel(),
            qSearch:'',
            listPhoto: [],
            urlUpload: process.env.VUE_APP_API_URL + "/gallery/api/photos",
            urlImage:  process.env.VUE_APP_API_URL + "/gallery/api/image/alt/",
        };
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode,
            isShowModal: state => state.isShowModal
        }),
        ...mapState("MemberListDialog", {
            memberlistModalName: state => state.modalName,
            memberlistShowModalMode: state => state.showModalMode,
            memberlistIsShowModal: state => state.isShowModal,
            selectedMembers: state => state.selectedMembers
        }),

        getPathImage(imagePath) {
            imagePath = (!imagePath) ? this.customPointModel.image_path : imagePath;
            if (
                imagePath &&
                this.customPointModel.image_path.indexOf(
                    process.env.VUE_APP_API_URL
                ) !== 0
            ) {
                let slash =
                    this.customPointModel.image_path.indexOf("/") === 0
                        ? ""
                        : "/";
                imagePath =
                    this.customPointModel.image_path.indexOf("http") === 0
                        ? this.customPointModel.image_path
                        : process.env.VUE_APP_API_URL +
                          slash +
                          this.customPointModel.image_path;
            }
            return imagePath;
        },
        modeView() {
            return this.showModalMode == "V";
        }
    },
    watch: {
        memberlistIsShowModal(value) {
            if (value === false) {
            }
        }
    },
    methods: {
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            showModal: "Crud/SHOW_MODAL",

            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
            memberListSetModal: "MemberListDialog/SET_MODAL",
            memberListShowModal: "MemberListDialog/SHOW_MODAL",
            memberListRefreshData: "MemberListDialog/REFRESH_TABLE",
            memberListHideModal: "MemberListDialog/HIDE_MODAL",
            setSelectedMembers: "MemberListDialog/SET_SELECTED_MEMBERS",
            setSelectedMember: "MemberListDialog/SET_SELECTED_MEMBER"
        }),
        filterMember(member){
            if(this.qSearch == '' || this.qSearch==null){
                return true;
            }
            let qSearch  = this.qSearch
                .trim(' ')
                .toLowerCase()
                .split(',')
                .filter(function (el) {
                    return el != null && el != '';
                });
            // console.log('qSearch',qSearch)
            let text = member.member_no+' '+member.name_on_card.toLowerCase();
            let patternSearch = new RegExp(qSearch.join('|'),'g');

            return text.toString().match(patternSearch);
        },
        memberListModal(){
            $("#" + this.modalName).modal('hide');
            this.memberListShowModal();
        },
        toggleRemove(item){
            if(this.customPointModel.attachments_delete.indexOf(item.id)!==-1){
                this.customPointModel.attachments_delete = _.without(this.customPointModel.attachments_delete,item.id);
            }
            else{
                this.customPointModel.attachments_delete.push(item.id);
            }
        },
        isNeedToRemove(item){
            return this.customPointModel.attachments_delete.indexOf(item.id)!==-1
        },
        updateData() {
            if (this.dataEdit) {
                blockUI.blockModal();
                this.setSelectedMembers([]);
                customPointRepository.getById(this.dataEdit.id).then(resp => {
                    blockUI.unblockModal();
                    resp.data.attachments_new = [];
                    resp.data.attachments_delete = [];
                    this.customPointModel = Object.assign({}, resp.data);
                    let members = resp.data.members ? resp.data.members : [];
                    this.setSelectedMembers(resp.data.members);
                });
            }else{
                this.setSelectedMembers([]);
            }
        },
        uploadPhoto: function(photo, file){
            var vx = this;
            const payload = JSON.stringify(photo);;

            GalleryRepository.uploadPhoto(payload)
            .then(response => {
                new Promise((resolve, reject) => {
                    // vx.$emit("event", resolve);
                    resolve(response);
                }).
                then((response) => {
                    // klau disini responsenya dibuat sebuat object lalu di push ke object utama
                    const data = {
                        uuid: file.upload.uuid,
                        image_path: response.data.alias,
                        image_id: response.data.alias
                    };

                    vx.customPointModel.attachments_new.push(data);
                    // jangan lupa hapus object photo dari list photo berdasarkan uuid proses
                    const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                    if (i > -1) {
                        vx.listPhoto.splice(i, 1);
                    }
                }).catch(error=>{
                   //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                    const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                    if (i > -1) {
                        vx.listPhoto.splice(i, 1);
                    }
                    $(file.previewElement)
                        .addClass("dz-error")
                        .find(".dz-error-message")
                        .text(
                            "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                        );
                });
            })
            .catch(error => {
                //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                if (i > -1) {
                    vx.listPhoto.splice(i, 1);
                }
                $(file.previewElement)
                    .addClass("dz-error")
                    .find(".dz-error-message")
                    .text(
                        "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                    );
            });
        },
        dropZone() {
            var vx = this;
            let dropzoneConfig = {
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 5, // MB
                acceptedFiles: "image/jpeg,image/png,image/jpg,image/gi",
                addRemoveLinks: true,
                // autoProcessQueue: false,
                withCredentials: true,
                createImageThumbnails: true,
                url: this.urlUpload,
                headers: {  // masukan header yang dibutukan bersama token
                    Accept: "application/json, text/plain, */*",
                    Authorization: "Bearer " + localStorage.tokens,
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/json;charset=UTF-8"
                },
                accept: function(file, done) {
                    // Bikin Promise, yang dilakukan adalah saat kita menerima gambar
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function(event) {
                            let photo = new Photo(); // bikin object payload image untuk di post ke API
                            var image = new Image(); // bikin object image kosong
                            image.src = event.target.result; // isi object image kosong dari file yang diupload
                            image.onload = function(i) { // saat load image masukan data image ke object payload image
                                photo.image = event.target.result.substring(
                                    event.target.result.indexOf(",") + 1
                                ); // base64 image
                                photo.title = file.name; // nama file
                                photo.description = file.name; // deskripsi biasanya pake nama juga
                                photo.imageContentType = file.type; // tipe file
                                photo.height = this.height; // tinggi foto
                                photo.width = this.width; // lebar foto
                                photo.taken = moment(file.lastModified); // waktu pengambilan
                                photo.uploaded = moment(); // waktu upload
                                photo.uuid = file.upload.uuid; // uuid untuk membedakan proses file upload
                                vx.listPhoto.push(photo); // masukan foto kedalam listphoto
                                resolve(true);
                            };
                        };
                    }).then(res => {
                        done(); // setelah proses masuk list photo selesai maka lanjutkan proses on sending
                    });
                },
                init: function() {
                    //proses pengiriman/post  gambar ke API disini kita overwrite plugin pengiriman nya pake json bukan formdata
                    // onsending dilakukan perfile berdasarkan total file yang kita pilih pas upload
                    this.on("sending", function(file, xhr, formData) {
                        // cari object photo untuk di post berdasarkan proses file yang dilakukan
                        let photo = vx.listPhoto.find(x => x.uuid == file.upload.uuid);
                        // kirim object photo
                        const x = JSON.stringify(photo);
                        xhr.send(x);
                    });
                    // kalau success upload nya maka anda akan mendapatkan response alias untuk disimpan
                    // success masuk per proses upload
                    this.on("success", function(file, response) {
                        // klau disini responsenya dibuat sebuat object lalu di push ke object utama
                        const data = {
                            data: '',
                            dataContentType: response.imageContentType,
                            dataUrl: response.alias,
                            id: null,
                            uuid: file.upload.uuid,
                            title: response.title,
                            image_path: response.alias,
                            image_id: response.alias,
                        };
                        vx.customPointModel.attachments_new.push(data);
                        // jangan lupa hapus object photo dari list photo berdasarkan uuid proses
                        const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                        if (i > -1) {
                            vx.listPhoto.splice(i, 1);
                        }
                        // load image/thumbnail
                        file.previewElement.querySelector("img").src = vx.urlImage + data.dataUrl;
                    });
                    this.on("error", function(file, message) {
                        //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                        const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                        if (i > -1) {
                            vx.listPhoto.splice(i, 1);
                        }
                        $(file.previewElement)
                            .addClass("dz-error")
                            .find(".dz-error-message")
                            .text(
                                "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                            );
                    });

                    this.on("removedfile", function(file) {
                        // hapus file kalau salah upload
                        // this.errors.remove('attachments');
                        const j = file.id
                        ? vx.customPointModel.attachments_new.findIndex(
                            x => x.id == file.id
                            )
                        : vx.customPointModel.attachments_new.findIndex(
                            x => x.uuid == file.upload.uuid
                            );
                        if (j > -1) {
                            vx.customPointModel.attachments_new.splice(j, 1);
                        }
                    });
                }
                // accept: (file, done) => {
                //     if (file.name == "justinbieber.jpg") {
                //         done("Naha, you don't.");
                //     } else {
                //         done();
                //     }
                // },
                // init: function() {
                //     // var replacedDataURL = file.dataURL.substring(file.dataURL.indexOf(",") + 1);
                //     // vx.photo.image = replacedDataURL;
                //     // vx.photo.imageContentType = file.type;
                //     // vx.photo.height = file.height;
                //     // vx.photo.width = file.width;
                //     // vx.photo.taken = moment(file.lastModified);
                //     // vx.photo.uploaded = moment();
                //     // });
                //     this.on("thumbnail", function(file) {
                //         let photo = new Photo();

                //         var replacedDataURL = file.dataURL.substring(
                //             file.dataURL.indexOf(",") + 1
                //         );
                //         photo.title = file.name; // nama file
                //         photo.description = file.name; // deskripsi biasanya pake nama juga
                //         photo.image = replacedDataURL;
                //         photo.imageContentType = file.type;
                //         photo.height = file.height;
                //         photo.width = file.width;
                //         photo.originName = file.name;
                //         photo.taken = moment(file.lastModified);
                //         photo.uploaded = moment();
                //         photo.uuid = file.upload.uuid; // uuid untuk membedakan proses file upload

                //         vx.uploadPhoto(photo, file);
                //     });
                // }
            };

            var myDropzone = new Dropzone("#kt-dropzone-one", dropzoneConfig);
            myDropzone.on("addedfile", file => {
                // this.customPointModel.attachments_new.push(file);
            });
            myDropzone.on("removedfile", file => {
                this.errors.remove("attachments_new");

                // hapus file kalau salah upload
                const j = file.id
                ? vx.customPointModel.attachments_new.findIndex(
                    x => x.id == file.id
                    )
                : vx.customPointModel.attachments_new.findIndex(
                    x => x.uuid == file.upload.uuid
                    );
                if (j > -1) {
                    vx.customPointModel.attachments_new.splice(j, 1);
                }
            });
        },
        getServerError(error, defaultMsg) {
            return _.isUndefined(error.response.data.message)
                ? defaultMsg
                : error.response.data.message;
        },
        async update(payload, couponUsageId) {
            var vx = this;
            await customPointRepository
                .update(payload, couponUsageId)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.$emit("event", resolve);
                        resolve(response);
                    }).then(response => {
                        vx.hideModal();
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Custom Poin berhasil diubah', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);

                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Custom Poin gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        async create(payload) {
            var vx = this;
            await customPointRepository
                .create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.$emit("event", resolve);
                        resolve(response);
                    })
                        .then(response => {
                            vx.hideModal();
                            let instance = new SweetAlert().showInfo({
                                swalTitle: "Berhasil!",
                                swalText: 'Data Custom Poin berhasil ditambahkan', //response.data.message,
                                swalType: "success",
                                onConfirmButton: function() {
                                    vx.refreshData(true);
                                    blockUI.unblockModal();
                                }
                            });
                        })
                        .catch(error => {});
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Custom Poin gagal ditambahkan."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        submitData() {
            this.errors.clear();
            if (this.selectedMembers.length == 0) {
                this.errors.add({
                    field: "selected_member",
                    msg: 'Silahkan pilih Member', // this.$t("sentences.invalid_attachments_new"),
                    rule: "required"
                });
            }
            this.$validator.validateAll().then(result => {
                if (result) {
                    var vx = this;
                    if (this.customPointModel.id) {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                let members = _.map(vx.selectedMembers, "id");
                                vx.customPointModel.members = members;
                                vx.update(
                                    vx.customPointModel,
                                    vx.customPointModel.id
                                );
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                // const data = new FormData();
                                // _.forEach(vx.customPointModel, (v, k) => {
                                //     if (v != null) {
                                //         if (k == "attachments_new") {
                                //             if (v.length > 0) {
                                //                 _.forEach(v, file => {
                                //                     data.append( "attachments[]", file );
                                //                 });
                                //             }
                                //         } else if (k == "attachments_delete") {
                                //             if (v.length > 0) {
                                //                 _.forEach(v, file => {
                                //                     data.append(
                                //                         "attachments_delete[]",
                                //                         file
                                //                     );
                                //                 });
                                //             }
                                //         } else {
                                //             if (
                                //                 k == "members" ||
                                //                 k == "member_number" ||
                                //                 k == "attachments"
                                //             ) {
                                //                 return;
                                //             }
                                //             data.append(k, v);
                                //         }
                                //     }
                                // });
                                _.forEach(vx.selectedMembers, v => {
                                    // data.append("members[]", v.id);
                                    vx.customPointModel.members.push(v.id);
                                });

                                vx.customPointModel.attachments = vx.customPointModel.attachments_new;
                                // vx.create(data);
                                vx.create(vx.customPointModel);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }

                    return;
                } else {
                }
            });
        }
    },
    mounted() {
        $("#" + this.modalName).modal();
        if (!this.modeView) {
            this.dropZone();
        }
        this.updateData();
    }
};
</script>
