export default function CustomPointModel() {
    return {
        id:null,
        type_custom:null,
        theme_custom:null,
        title:null,
        subtitle:null,
        point:null,
        created_at:null,
        members:[],
        attachments:[],
        attachments_new:[],
        attachments_delete:[],
        };
}
