<template v-if="tableFilter">
    <form @submit.prevent="onClickSearch">
        <div class="row filter-member">
            <div class="col-md-6">
                <div class="col-md-12">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label" >ID:</label>
                            <div class="col-lg-8">
                                <input type="text" class="form-control" v-model="filterModel.id" placeholder="ID" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label" >{{ $t('member.name_on_card') }}:</label>
                            <div class="col-lg-8">
                                <input type="text" class="form-control" v-model="filterModel.name_on_card" :placeholder="$t('member.name_on_card')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label" >{{ $t('member.member_no') }}:</label>
                            <div class="col-lg-8">
                                <input type="text" class="form-control" v-model="filterModel.member_no" :placeholder="$t('member.member_no')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label" >{{ $t('member.loyalty_tier') }}:</label>
                            <div class="col-lg-8">
                                <select-dropdown-multiple
                                    :render-select="loyaltyTierRenderSelect"
                                    repo-name="loyaltyTierRepository"
                                    @update:changedValue="onLoyaltyTierSelected"
                                    ref="loyaltyTierSelectVue"
                                    :selected-dropdown="loyaltyTierIds"
                                    :id-select-dropdown="'tiers_filter_member'"
                                    ></select-dropdown-multiple>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                </div>
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label" >{{ $t('member.point') }}:</label>
                        <div class="col-lg-4">
                            <input type="text" class="form-control" v-model="filterModel.point_min" :placeholder="$t('member.point')+' Minimal'" />
                        </div>
                        <div class="col-lg-4">
                            <input type="text" class="form-control" v-model="filterModel.point_max" :placeholder="$t('member.point')+' Maksimal'" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label" >{{ $t('member.loyalty') }}:</label>
                        <div class="col-lg-4">
                            <input type="text" class="form-control" v-model="filterModel.loyalty_min" :placeholder="$t('member.loyalty')+' Minimal'" />
                        </div>
                        <div class="col-lg-4">
                            <input type="text" class="form-control" v-model="filterModel.loyalty_max" :placeholder="$t('member.loyalty')+' Maksimal'" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label" >{{ $t('member.joined_date') }}:</label>
                        <div class="col-lg-8">
                            <date-range-picker :changedValueStart.sync="filterModel.joined_date_min" :changedValueEnd.sync="filterModel.joined_date_max" id-date-picker="filter_rangedatepicker"  place-holder="DD/MM/YYYY - DD/MM/YYYY"></date-range-picker>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="btn btn-primary"><i class="fa fa-search"></i>{{ $t('button.search') }}</button>
                    <button type="button" @click="onClickReset" class="btn btn-secondary">{{ $t('button.reset') }}</button>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import DateRangePicker from './../../../../components/_general/DateRangePicker.vue';
import SelectDropdownMultiple from "./../../../components/base/SelectDropdownMultiple";
import FilterModel from '../../../model/filter-model'
    export default {
        components:{
            DateRangePicker,
            SelectDropdownMultiple
        },
        mounted(){
            this.resetForm();
        },
        data(){
            return {
                filterModel:new FilterModel,
                searchArgs:[]
            }
        },
        computed:{
            loyaltyTierIds(){
                if(this.filterModel){
                    return _.map(this.filterModel.loyalty_tier_id,'id');
                }
                return [];
            },
        },
        watch:{
            filterModel(value){
                // console.log(value)
            }
        },
        props:{
            tableFilter: { type: Array },
        },
        methods:{
            onLoyaltyTierSelected(value){
                let ids = _.map(value,'id');
                try{
                    this.filterModel = Object.assign(this.filterModel,{
                        loyalty_tier_id:ids
                    });
                }
                catch(e){ }
                // console.log(value,ids);
            },
            loyaltyTierRenderSelect(row){
                return row.name;
            },
            resetForm(){
                this.searchArgs = [];
                $('#filter_rangedatepicker').val('');
                this.filterModel= new FilterModel;
            },
            onClickReset(){
                this.resetForm();
                this.$emit('click-search',null);
            },
            onClickSearch(){
                let sArgs = {};
                _.forEach(this.filterModel,(value,key)=>{
                    if(value != null && value != ''){
                        if(key.endsWith('_min')){
                            
                            let newK = key.substring(0, key.length - 4);
                            // console.log(sArgs[newK]);
                            sArgs[newK] = typeof(sArgs[newK])=='undefined'? [] : sArgs[newK];
                            sArgs[newK][0]=value;
                        }
                        else if(key.endsWith('_max')){
                            let newK = key.substring(0, key.length - 4);
                            // console.log(sArgs[newK]);
                            sArgs[newK] = typeof(sArgs[newK])=='undefined'? [] : sArgs[newK];
                            sArgs[newK][1]=value;
                        }
                        else{
                            sArgs[key]=value;
                        }
                    }
                })
                this.searchArgs = [];
                _.forEach(sArgs,(value,key)=>{
                    if('object' == typeof(value) && 'loyalty_tier_id' != key){
                        value[0]=( value[0] == '' || value[0] == null || value[0] == undefined )?0:value[0];
                        value[1]=( value[1] == '' || value[1] == null || value[1] == undefined )?0:value[1];
                    }

                    let searchField = { 
                        name:key, 
                        type: typeof(value) == 'object' || typeof(value) == 'array' ?'between':'contains', 
                        value: typeof(value) == 'object' || typeof(value) == 'array' ? value.join(',') : value
                    };
                    if('loyalty_tier_id'==key){
                        searchField.type = 'in';
                    }
                    this.searchArgs.push(searchField);
                })
                // console.log('this.searchArgs',this.searchArgs);
                this.$emit('click-search',this.searchArgs)
            }
        }
    }
</script>