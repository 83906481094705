<template>
    <div :id="modalName" class="modal" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Member List</h5>
                    <div class="text-right">
                        <button type="button"  @click="submitData"
                            class="btn btn-primary btn-bold btn-upper btn-font-md">Selesai</button>
                    </div>
                </div>
                <div class="modal-body">
                    <member-list-table :permission="{}" ></member-list-table>
                </div>

                <div class="modal-footer">
                    <button type="button"  @click="submitData"
                        class="btn btn-primary btn-bold btn-upper btn-font-md">Selesai</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import { isNullOrUndefined } from "util";
    import { Validator } from "vee-validate";
    import VeeValidate from "vee-validate";
    import MemberListTable from "./../base/crud-table/MemberListTable";
    import MemberRepository from './../../repositories/MemberRepository';
    import { mapState, mapMutations, mapActions } from "vuex";
    import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
    import BlockUI from "./../../../components/_js/BlockUI";
    const blockUI = new BlockUI();
    const SweetAlert = Vue.extend(SweetAlertTemplate);

    VeeValidate.setMode('passive');
    export default {
        components:{ MemberListTable },
        watch:{

        },
        computed:{
            ...mapState("MemberListDialog", {
                modalName: state => state.modalName,
                dataEdit: state => state.dataEdit,
                showModalMode: state => state.showModalMode,
                isShowModal: state => state.isShowModal,
                selectedMembers: state => state.selectedMembers,
            }),
        },
        methods:{
            ...mapMutations({
                setRepository: 'MemberListDialog/SET_REPOSITORY',
                hideModal: 'MemberListDialog/HIDE_MODAL',
                setSelectedMembers:'MemberListDialog/SET_SELECTED_MEMBERS',
            }),
            submitData(){
                this.$emit('hidemodal',true);
                this.hideModal();
            }
        },
        data(){
            return {
                tempSelectedMembers:[]
            }
        },
        created(){
            this.setRepository(MemberRepository);
        },
        mounted(){
            $("#" + this.modalName).modal();
        }
    }
</script>
