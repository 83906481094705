export default function FilterModel() {
    return {
        id:null,
        name_on_card:null,
        member_no:null,
        loyalty_tier:[],
        point_min:null,
        point_max:null,
        loyalty_min:null,
        loyalty_max:null,
        joined_date_min:null,
        joined_date_max:null,
    }
}