<template>
  <input
    type="text"
    class="form-control input-datepicker"
    v-bind:id="idDatePicker"
    v-bind:placeholder="placeHolder"
    :value="dateValue"
    autocomplete="off"
  >
</template>

<script>
export default {
  data() {
    return {
      dataResponse: null,
      dateValue: null
    };
  },
  props: {
    idDatePicker: { type: String },
    pickerValue: {},
    placeHolder: { type: String }
  },
  // computed: {
  //   selectedValue() {
  //     console.log(this.dateValue);
  //   }
  // },
  methods: {
    getDatePicker: function() {
      $("#" + this.idDatePicker)
        .daterangepicker({
          buttonClasses: "btn btn-sm",
          applyClass: "btn-primary",
          cancelClass: "btn-secondary"
        })
        .on("change", () => {
          this.dateValue = $("#" + this.idDatePicker).val();
          var dateRange = this.dateValue.replace(/ /g, "").split("-");

          var dateStart = moment(dateRange[0], "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          var dateEnd = moment(dateRange[1], "DD/MM/YYYY").format("YYYY-MM-DD");

          this.$emit("update:changedValueStart", dateStart);
          this.$emit("update:changedValueEnd", dateEnd);
        });
    },
    clearData: function() {
      this.dateValue = null;
    }
  },
  mounted() {
    this.getDatePicker();
    this.$emit("update:changedValueStart", moment().format("YYYY-MM-DD"));
    this.$emit("update:changedValueEnd", moment().format("YYYY-MM-DD"));
  }
};
</script>

